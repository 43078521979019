.App {
  text-align: center;
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700;900&display=swap');

.Account-Button {
  font-size: 18px !important;
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
}

.Downloads-Button {
  font-size: 18px !important;
  padding-right: 40px !important;
  position: absolute !important;
  top: 10px !important;
  right: 70px !important;
}

.App-logo1 {
  height: 40vmin;
  pointer-events: none;
}

.FAQText {
  font-family: 'Lato' !important;
  font-size: 32px !important;
  font-weight: 700;
}

.App-logo {
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.FAQAccordian {
  background-color: #3E1358;
}

.Title {
    color: #24D888;
    font-family: 'Oswald', sans-serif !important;
    font-weight: 700 !important;
    font-size: 5rem !important;
}
.Mobile-Title {
  color: #24D888;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 700 !important;
  font-size: 4rem !important;
}

.sub-Title1 {
  color: #f1f1f1;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 200 !important;
  font-size: 2rem !important;
}

.Mobile-subTitle1 {
  color: #f1f1f1;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 200 !important;
  font-size: 1.5rem !important;
}

.sub-Title2 {
  color: #f1f1f1;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 400 !important;
  font-size: 2.5rem !important;
}
.sub-Title3 {
  color: #000000;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.sub-Title4 {
  color: #000000;
  font-family: 'Lato', sans-serif !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}

.sub-Title5 {
  color: #f1f1f1;
  font-family: 'Lato', sans-serif !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}

.instruction-text {
  color: #000000;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.Main_BG {
  background-image: url('logos/main_bg1.png');
}

.info-box {
  width: 100%;
  height: 50px;
  background-color: #3E1358;
  color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  center;
}

.info-box2 {
  width: 100%;
  height: 50px;
  background-color: #24D888;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  center;
}
.info-box3 {
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  center;
}
.color-purple {
  background-color: #3E1358;
}

.color-green {
  color: #24D888;
}


.color-dark-purple {
  background-color: #1E092C;
}

.color-off-white {
  color: #F1F1F1 !important;
}

.standard-page {
  background-color: #f1f1f1;
  color: #000000;
  display: flex;
  flex-direction: column;
}
.App-button-new {
  background-color: #24D888;
  color: #f1f1f1;
  font-family: 'Oswald', sans-serif;
  font-size:(10px + 2vmin);
}

.App-header-new {
  background-image: url('logos/backimage.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Center the image */
  background-position-y: center;
  vertical-align: middle;
  background-color: #1E092C;
  min-height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Oswald', sans-serif;
  font-size: calc(10px + 2vmin);
  color: #F1F1F1;
}

.App-Body {
  background-image: url('logos/main_bg3.png');
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-position-y: 0;
  background-size: 1024px;
  background-color: #1E092C;
  min-height: 70vh;
  display: flex;              /* establish flex container */
  flex-direction: column;     /* stack flex items vertically */
  justify-content: top;    /* center items vertically, in this case */
  align-items: center;        /* top align items horizontally, in this case */
  padding-top: 25px;
  
  font-family: 'Oswald', sans-serif;
  font-size: calc(10px + 2vmin);
  color: #F1F1F1;
    
  top: 10px;
}

.App-Body-FAQTable {
  background-color: #1E092C;
  margin-left: 15%;
  margin-right: 15%; 
}

.App-header {
  background-color: #ffffee;
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: mediumblue;
}

.Button-block {
  display: inline-block;
  padding: 5px;
}

.Header-area {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.Container {
  margin: 5%;
}

.Container-centered {
  margin: 5%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 15%;
}

.Container-flex {
  margin: 5px;
  display: flex;
}

.Right-aligned {
  text-align: right;
}
.Stripe-payment_container {
  margin: 0 auto;
  min-width: 480px;
  align-items: center;
}

.PmtTable {
  align-items: center;
  min-width: 650px;
  max-width: 800px;
  width: 50%
}

.FAQTable {
  min-width: 800px;
}

.table-cell-left {
  padding-left: 0px !important;
}

.table-cell-right {
  padding-right: 0px !important;
  text-align: end !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
